
<span *ngIf="this.warningMessage" class="slds-icon_container slds-text-color_error" title="Warning">
  <svg class="slds-icon slds-icon_xx-small slds-icon-text-warning" aria-hidden="true">
      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#warning"></use>
  </svg>
  {{this.warningMessage}}
</span>
<div class="slds-grid slds-grid_vertical identifyColumnsContainer">
  <div class="slds-grid identifyColumnsText">
    <div class="slds-col slds-size_1-of-2">
      <span class="title-cols" title="{{'MappingFieldsComponent:step_select_columns_col_file' | translate:'File columns' }}">{{'MappingFieldsComponent:step_select_columns_col_file' | translate:'File columns' }}</span>
    </div>
    <div class="slds-col slds-size_1-of-2">
      <span class="title-cols" title="{{'MappingFieldsComponent:step_select_columns_col_values' | translate:'Corresponding value' }}">{{'MappingFieldsComponent:step_select_columns_col_values' | translate:'Corresponding value'}}</span>
    </div>
    
  </div>
    <div class="slds-grow slds-scrollable_y columnsContainer">
      <ng-template ngFor let-dfWrapper [ngForOf]="dataFieldWrappers" let-i="index">
      <div class="slds-grid slds-p-around_xx-small">
        <div class="slds-col slds-size_1-of-2">
          <div class="slds-grid">
            <div class="slds-col slds-truncate">
              <div class="slds-form-element">
                <div class="slds-form-element__control slds-truncate">
                  <div class="slds-checkbox slds-truncate">
                    <input type="checkbox" [(ngModel)]="dfWrapper.selected" name="{{dfWrapper.field.name}}" id="{{dfWrapper.field.name}}-{{i}}" (change)="onChangeSelect()"/>
                    <label class="slds-checkbox__label" for="{{dfWrapper.field.name}}-{{i}}">
                      <span class="slds-checkbox_faux"></span>
                      <span class="slds-form-element__label">{{dfWrapper.field.alias}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
          
          
        </div>
        <div class="slds-col slds-size_1-of-2">
          <div class="slds-form-element" style="display: block;">
            <div class="slds-form-element__control">
              <div class="slds-select_container">
                  <select #selectBusinessType [(ngModel)]="dataFieldWrappers[i].businessFieldWrapper" (ngModelChange)="onChangeSelectCheck()" class="slds-select" id="select-mapping-{{i}}">
                    <ng-container *ngFor="let fieldWrapper of businessFieldWrappers">
                      <option *ngIf="fieldWrapper.field.visible" [selected]="preselectedBusinessType(dataFieldWrappers[i].businessFieldWrapper?.field?.businessType, fieldWrapper.field.businessType, i)" [ngValue]="fieldWrapper" [disabled]="fieldWrapper.field.alias === '________'">{{fieldWrapper.field.alias}}</option>
                    </ng-container>
                    </select>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      </ng-template>
      
    </div>
  </div>