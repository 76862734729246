import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { BusinessType, DataTable, Field, FieldType, DataType } from '@galigeo-store/shared-models';
import {  LanguageService } from '@galigeo-store/ui-lightning';
import { LocaleMappingFieldsComponent } from '../locales/localeMappingFieldsComponent';

@Component({
  selector: 'retail-mapping-fields',
  templateUrl: './mapping-fields.component.html',
  styleUrls: ['./mapping-fields.component.css']
})
export class MappingFieldsComponent implements OnInit {
  @Input() businessFields!: Field[];
  @Input() dataTable!: DataTable;
  @Input() isLocationRequired!: boolean;
  @Output() mappingResult = new EventEmitter<MappingFieldsEvent>();
  public warningMessage?: string;
  public originalDataTable!: DataTable;
  public businessFieldWrappers: MappingFieldWrapper[] = [];
  public dataFieldWrappers: MappingFieldWrapper[] = [];

  @ViewChildren('selectBusinessType') selectBT!: QueryList<ElementRef>;

  constructor(private languageService: LanguageService) {
    this.languageService.addTranslation('MappingFieldsComponent', new LocaleMappingFieldsComponent().locale);
  }

  ngAfterViewInit() {
    this.selectBT.changes.subscribe(() => {
      console.log(this.selectBT);
    });
  }
  ngOnInit(): void {
     this.originalDataTable = this.dataTable.clone();

    // create the aliases and set the visibilty
    this.dataTable?.fields.forEach((field: any) => {
      if (!field.alias) {
          field.alias = field.name;
      }
      const bf = this.getBusinessField(field.businessType);
      field.visible = bf ? bf.visible : true;
      this.businessFieldWrappers = [];
      this.businessFields.forEach((businessField: Field) => {
        this.businessFieldWrappers.push(new MappingFieldWrapper(businessField, true));
      });
      this.businessFieldWrappers.push(new MappingFieldWrapper({name: '________', alias: '________', visible: true}, false));
      this.businessFieldWrappers.push(new MappingFieldWrapper({name: 'string', alias: 'Texte', type: FieldType.STRING, visible: true}, false));
      this.businessFieldWrappers.push(new MappingFieldWrapper({name: 'boolean', alias: 'Booléen', type: FieldType.BOOLEAN, visible: true}, false));
      this.businessFieldWrappers.push(new MappingFieldWrapper({name: 'integer', alias: 'Entier',type: FieldType.INTEGER, visible: true}, false));
      this.businessFieldWrappers.push(new MappingFieldWrapper({name: 'double', alias: 'Décimal', type: FieldType.DOUBLE, visible: true}, false));
      this.businessFieldWrappers.push(new MappingFieldWrapper({name: 'date', alias: 'Date', type: FieldType.DATE, visible: true}, false));

      this.dataFieldWrappers = [];
      this.dataTable.fields.forEach((field: Field) => {
        const wrapper = this.preselectedBT(field);
        this.dataFieldWrappers.push(wrapper);
      });

      this.mappingResult.emit(this.getMappingFieldsEvent());

      // All fields are selected by default
      field.selected = true;
    });
  }

  private getBusinessField(businessType: string) {
    return this.businessFields.find((f: any) => f.businessType === businessType);
}

  private preselectedBT(field: Field) : MappingFieldWrapper {
    const wrapper = new MappingFieldWrapper(field, false);
    wrapper.hasBusinessType = true;
    if (field.businessType) {
      const associatedType = this.businessFieldWrappers.find((bfw: MappingFieldWrapper) => bfw.field.businessType === field.businessType);
      if (associatedType) {
        wrapper.businessFieldWrapper = associatedType;
      }
    } else {
      const nameLower = field.name.toLowerCase();
      switch (nameLower) {
          case 'id':
          case 'identifier':
              wrapper.businessFieldWrapper = this.businessFieldWrappers.find((bfw: MappingFieldWrapper) => bfw.field.businessType === BusinessType.ID) ?? null;
              break;
          case 'nom':
          case 'name':
              wrapper.businessFieldWrapper = this.businessFieldWrappers.find((bfw: MappingFieldWrapper) => bfw.field.businessType === BusinessType.NAME) ?? null;
              break;
          case 'x':
          case 'gpsx':
          case 'longitude':
          case 'long':
          case 'x_long':
              wrapper.businessFieldWrapper = this.businessFieldWrappers.find((bfw: MappingFieldWrapper) => bfw.field.businessType === BusinessType.LONG) ?? null;
              break;
          case 'y':
          case 'gpsy':
          case 'latitude':
          case 'lat':
          case 'y_lat':
              wrapper.businessFieldWrapper = this.businessFieldWrappers.find((bfw: MappingFieldWrapper) => bfw.field.businessType === BusinessType.LAT) ?? null;
              break;
          case 'address':
          case 'adresse':
              wrapper.businessFieldWrapper = this.businessFieldWrappers.find((bfw: MappingFieldWrapper) => bfw.field.businessType === BusinessType.ADDRESS) ?? null;
              break;
          case 'commune':
          case 'city':
          case 'ville': 
              wrapper.businessFieldWrapper = this.businessFieldWrappers.find((bfw: MappingFieldWrapper) => bfw.field.businessType === BusinessType.CITY) ?? null;
              break;
          case 'cp':
          case 'code postal':
          case 'code_postal':
          case 'postal_code':
              wrapper.businessFieldWrapper = this.businessFieldWrappers.find((bfw: MappingFieldWrapper) => bfw.field.businessType === BusinessType.POSTAL_CODE) ?? null;
              break;
      }
    } 
    if (!wrapper.businessFieldWrapper) {
      wrapper.hasBusinessType = false;
      wrapper.businessFieldWrapper = this.businessFieldWrappers.find((bfw: MappingFieldWrapper) => bfw.field.name === field.type) ?? null;
    }
    return wrapper;
  }
  
  onChangeSelectCheck() {
    this.mappingResult.emit(this.getMappingFieldsEvent());
  }

  onChangeSelect() {
    this.mappingResult.emit(this.getMappingFieldsEvent());
  }

  preselectedBusinessType(fiBusinessType: any, bt: any, fieldIndex: number) {
    if (fiBusinessType && fiBusinessType === bt) {
      const selectBTElement = this.selectBT?.toArray()[fieldIndex]?.nativeElement;
      if (selectBTElement && selectBTElement.style.fontWeight != 500) {
        selectBTElement.style.background = '#e8fff2';
        selectBTElement.style.fontWeight = '500';
      } 
      return true;
    } else {
      return false;
    }
  }

  getMappingFieldsEvent(): MappingFieldsEvent {
    let mf = new MappingFieldsEvent(this.dataTable, this.dataFieldWrappers, this.isLocationRequired);
    let valid = mf.isValid();
    this.warningMessage = valid ? undefined : `${this.languageService.getTranslation('MappingFieldsComponent', 'step_select_columns_duplicate')}`;
    return mf;
  }
}

export class MappingFieldsEvent {
  private dataTable!: DataTable;
  private dataFieldWrappers!: MappingFieldWrapper[];
  private isLocationRequired!: boolean;

  constructor(dataTable: DataTable, dataFieldWrappers: MappingFieldWrapper[], isLocationRequired: boolean) {
    this.dataTable = dataTable;
    this.dataFieldWrappers = dataFieldWrappers;
    this.isLocationRequired = isLocationRequired;
  }

  public isValid() : boolean {
    const dupFields: Set<string> = new Set();
    const firstTime: Set<string> = new Set();
    for (const field of this.dataTable.fields) {
      if (field.businessType && firstTime.has(field.businessType)) {
        dupFields.add(field.name);
      } else if (field.businessType) {
        firstTime.add(field.businessType);
      }
    }

    const hasSelectedBusinessType = (businessType: BusinessType) =>
      this.dataFieldWrappers.some((f: MappingFieldWrapper) => f.businessFieldWrapper?.field.businessType === businessType && f.selected);
  
    const addressCondition = hasSelectedBusinessType(BusinessType.ADDRESS);
    const latLongCondition = hasSelectedBusinessType(BusinessType.LAT) && hasSelectedBusinessType(BusinessType.LONG);
    const cityCondition = hasSelectedBusinessType(BusinessType.CITY);
    const postalCodeCondition = hasSelectedBusinessType(BusinessType.POSTAL_CODE);

    if (this.isLocationRequired) {
      return (addressCondition || latLongCondition || cityCondition || postalCodeCondition) && dupFields.size === 0;
    }

    return dupFields.size === 0;
  }

  public getResult(): DataTable {

    this.dataFieldWrappers.forEach((fieldWrapper: MappingFieldWrapper) => {
      if (fieldWrapper.businessFieldWrapper?.hasBusinessType) {
        fieldWrapper.field.businessType = fieldWrapper.businessFieldWrapper.field.businessType;
        fieldWrapper.field.type = fieldWrapper.businessFieldWrapper.field.type;
        if (fieldWrapper.field.businessType === BusinessType.LAT || fieldWrapper.field.businessType === BusinessType.LONG) {
          fieldWrapper.field.visible = false;
        }
      } else {
        fieldWrapper.field.type = fieldWrapper.businessFieldWrapper?.field.type;
      }
    });

    this.dataTable.fields = this.dataFieldWrappers.map((fieldWrapper: MappingFieldWrapper) => fieldWrapper.field);
    this.dataTable.data.forEach((row: any) => {
      this.dataFieldWrappers.forEach((fieldWrapper: MappingFieldWrapper, index: number) => {
        const value = row.values[index];
        switch (fieldWrapper.field.type) {
          case FieldType.BOOLEAN:
            row.values[index] = DataType.convertToBoolean(value);
            break;
          case FieldType.INTEGER:
            row.values[index] = DataType.convertToInteger(value);
            break;
          case FieldType.DOUBLE:
            row.values[index] = DataType.convertToDouble(value, fieldWrapper.field.type ?? FieldType.STRING);
            break;
          case FieldType.DATE:
            row.values[index] = DataType.convertToDate(value);
            break;
          case FieldType.STRING:
            row.values[index] = DataType.convertToString(value);
            break;
          default:
        }
      });
    });

    this.dataFieldWrappers.forEach((fieldWrapper: MappingFieldWrapper) => {
      if (!fieldWrapper.selected) {
        this.dataTable.deleteCollumn(fieldWrapper.field.name);
      }
    });
    return new DataTable({data: this.dataTable.data, fields: this.dataTable.fields});
  }
}


export class MappingFieldWrapper {
  dropdownValue : string;
  field: Field;
  hasBusinessType: boolean;
  businessFieldWrapper: MappingFieldWrapper | null = null;
  selected: boolean = true;

  constructor(field: Field, hasBusinessType : boolean) {
    this.field = field;
    this.hasBusinessType = hasBusinessType;
    this.dropdownValue = field.businessType ?? field.type ?? '';
  } 
}
