<div class="slds-grid mainContainer">
  <div class="slds-col slds-size_1-of-2">
    <retail-edit-map
      *ngIf="network !== undefined && showMap"
      [network]="network!"
      [addPoi]="addPoi"
      (action)="onMapEvent($event)"
      (closeAddPoi)="addPoi = false"
    ></retail-edit-map>
  </div>
  <div>
    <retail-map-alerts *ngIf="network.pois && geocodageAlertActive" [alerts]="geocodageAlertArray"
    (close)="closeAlertGeocodage()"></retail-map-alerts>
  </div>
  <div *ngIf="network && network.pois" class="slds-col slds-size_1-of-2" style="padding: 1rem;">
    <div class="network-header slds-grid slds-wrap">
      <div *ngIf="networkListOptions" class="network-drpodown slds-m-right_small">
        <ui-lightning-dropdown [cssClass]="'dropdown_item'" [cssClassBtn]="'slds-button_neutral'" [title]="network.name || ''" [tooltip]="network.name ?? ''"  [icon]="'down'">
          <ng-template ngFor let-subItem [ngForOf]="networkListOptions.items" let-j="index">
            <ui-lightning-dropdown-item>
              <a href="#" role="menuitem" tabindex="0" (mousedown)="selectNetwork(subItem)">
                <!--div *ngIf="subItem.logo" class="dropdownItem">
                  <img *ngIf="subItem.logo" class="dropdownItem_logo" src="{{subItem.logo}}" alt="{{subItem.name}}" />
                </div-->
                <div [ngClass]="{'isSelected' : isSelected(subItem)}" >
                  <span class="slds-truncate network-drpodown-item-text" title="{{subItem.name}}">{{subItem.name}}</span>
                </div>
                  <ui-lightning-icon *ngIf="subItem.icon"  size="x-small"></ui-lightning-icon>
              </a>
            </ui-lightning-dropdown-item>
          </ng-template>
        </ui-lightning-dropdown>
      </div>
      <!--div [retailHasCapability]="'editNetwork'" *ngIf="networkMenuOptions" class="edit-drpodown slds-col slds-p-horizontal_medium">
        <ui-lightning-menu [items]="networkMenuOptions" (action)="selectMenu($event)"></ui-lightning-menu>
      </div-->
      <div class="slds-grid_align-spread slds-grid" style="flex-grow: 2;">
        <div class="edit-buttons slds-button-group" role="group">
          <button title="{{'NetworkComponent:rename' | translate:'Rename'}}" class="slds-button slds-button_neutral rename-button" [retailHasCapability]="'editNetwork'" (click)="selectMenu({action: 'rename'})">
            <ui-lightning-icon alternativeText="{{'NetworkComponent:rename' | translate:'Rename'}}" [iconName]="'utility:edit'" size="small"></ui-lightning-icon>
          </button>
          <button title="{{'NetworkComponent:duplicate' | translate:'Duplicate'}}" class="slds-button slds-button_neutral duplicate-button" [retailHasCapability]="'editNetwork'" (click)="selectMenu({action: 'duplicate'})">
            <ui-lightning-icon alternativeText="{{'NetworkComponent:duplicate' | translate:'Duplicate'}}" [iconName]="'utility:file'" size="small"></ui-lightning-icon>
          </button>
          <button title="{{'NetworkComponent:update_network' | translate:'Update network'}}" class="slds-button slds-button_neutral update-button" [retailHasCapability]="'editNetwork'" (click)="selectMenu({action: 'update'})">
            <ui-lightning-icon alternativeText="{{'NetworkComponent:update_network' | translate:'Update network'}}"[iconName]="'utility:sync'" size="small"></ui-lightning-icon>
          </button>
          <button title="{{'NetworkComponent:export' | translate:'Export'}}" class="slds-button slds-button_neutral" [retailHasCapability]="'editNetwork'" (click)="selectMenu({action: 'export'})">
            <ui-lightning-icon alternativeText="{{'NetworkComponent:export' | translate:'Export'}}"[iconName]="'utility:upload'" size="small"></ui-lightning-icon>
          </button>
          <button title="{{'NetworkComponent:delete_network' | translate:'Delete network'}}" class="slds-button slds-button_neutral" [retailHasCapability]="'editNetwork'" (click)="selectMenu({action: 'delete'})">
            <ui-lightning-icon alternativeText="{{'NetworkComponent:delete_network' | translate:'Delete network'}}"[iconName]="'utility:delete'" size="small" variant="error"></ui-lightning-icon>
          </button>
        </div>

        <button style="font-size: 18px; color: #1B96FF;" [retailHasCapability]="'editNetwork'" (click)="addNetwork()" class="slds-button slds-button_neutral add-network-button"><svg class="slds-button__icon slds-button__icon_left slds-icon_small" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#add"></use>
        </svg><span class="add-network-text">{{'NetworkComponent:add_network' | translate:'Add network'}}</span></button>
        <!--ui-lightning-switch label="Réseau par défaut" class="slds-col_bump-left slds-align-middle" [defaultChecked]="network.isDefault" (switchState)="changeDefaultNetwork($event)"></ui-lightning-switch-->
      </div>
    </div>
    
    <div class="align-line slds-gutters table-menu-container" >
      <div>
        <button [retailHasCapability]="'editNetwork'" (click)="onClickAddPoi()" class="slds-button slds-button_neutral add-poi-button" disabled="{{ addPoi ? !addPoi : null}}"><svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#add"></use>
        </svg>{{'NetworkComponent:add' | translate:'Add'}}</button>
        <button [retailHasCapability]="'editNetwork'" *ngIf="atLeastOneRowChecked()" (click)="deleteRows()" class="slds-button slds-button_text-destructive"><svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#delete"></use>
        </svg>{{'NetworkComponent:delete' | translate:'Delete'}}</button>
      </div>
        <div class="last-update">
          <span style="float: right;">{{'NetworkComponent:created_by' | translate:'Created by'}} : &#64;{{createdBy}} - {{createdAt}}</span>
          <span *ngIf="geocodeFailedNb > 0 || geocodeToCheckNb > 0" style="display: inline-block;"><ui-lightning-icon [iconName]="'utility:warning'" variant="warning" size="xx-small"></ui-lightning-icon> {{geocodeToCheckNb}} <ui-lightning-icon [iconName]="'utility:error'" variant="error" size="xx-small"></ui-lightning-icon> {{geocodeFailedNb}} /&nbsp;</span>
        </div>
    </div>

   <div class="network-table" [ngStyle]="{'height': 'calc( 100vh - 232px)'}">
    <ui-lightning-table *ngIf="network.pois" [dataTable]="network.pois" [options]="tableOptions" [name]="'table-network'"></ui-lightning-table>
  </div>
   </div>
    
</div>
<ui-lightning-modal *ngIf="network" [isOpen]="dialogStep ? true : false" (onClose)="closeModalEditNetwork($event)">
  <ui-lightning-stepper-card *ngIf="dialogStep" [step]="dialogStep"
          (actionClick)="cardStepperClick($event)">
          <ng-container [ngSwitch]="dialogStep.name">
            <ng-container *ngSwitchCase="StepNames.NETWORK_NAME">
              <div>
                <div class="slds-form-element">
                  <div class="slds-form-element__control">
                    <input type="text" id="text-input-id-47"
                      placeholder="{{this.placeholder}}"
                      (keydown)="this.dialogStep.btns[0].disabled = true"
                      (keyup)="setNetworkName($event)" [(ngModel)]="network.name"
                      class="slds-input add-network-input slds-size_full" />
                      <span *ngIf="this.alreadyExist">{{'NetworkComponent:already_exist' | translate:'Please do not reuse an existing name'}} </span>
                      <span *ngIf="!this.alreadyExist && !this.validLength">{{'NetworkComponent:exceed_name' | translate:'Please do not exceed 30 characters'}} </span>

                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="StepNames.ADD_LOGO">
              <div>
                <ui-lightning-file-import [options]="importLogoOptions"
                  (onChange)="onLogoLoaded($event)"></ui-lightning-file-import>
              </div>
            </ng-container>
          </ng-container>
  </ui-lightning-stepper-card>
</ui-lightning-modal>
<ui-lightning-modal *ngIf="network && dialogLogoEdit" [isOpen]="dialogLogoEdit" (onClose)="closeModalEditLogo($event)">
  <ui-lightning-stepper-card *ngIf="dialogLogoEdit" [step]="dialogLogoEdit"
  (actionClick)="cardStepperClick($event)">
    <div>
      <ui-lightning-file-import *ngIf="dialogLogoEdit" [options]="importLogoOptions"
        (onChange)="onLogoPoisChange($event)"></ui-lightning-file-import>
    </div>
  </ui-lightning-stepper-card>
 
  
   
</ui-lightning-modal>