<div class="mapElement" id="map-wizard">
    <div class="map-controls" style="width: max-content;">
        <div class="group-1 control" [ngStyle]="{'width': showControlLookup ? '250px' : ''}">
            <ui-lightning-search-adress title="add poi" style="width: 100%;" #controlLookup class="control-lookup" *ngIf="showControlLookup" [lookupOptions]="controlLookupOptions" (onSelect)="flyToAdress($event)"
    (onClose)="showControlLookup = !showControlLookup"></ui-lightning-search-adress>
            <button title="{{'MapComponent:button_search' | translate}}" (click)="showAdressLookup(1, $event)" *ngIf="!showControlLookup"><ui-lightning-icon [iconName]="'utility:search'" size="xx-small" alternativeText="{{'MapComponent:button_search' | translate}}"></ui-lightning-icon></button>
        </div>
        <div class="group-2 control">
            <button title="{{'MapComponent:button_zoom_network' | translate}}" (click)="setExtent()"><ui-lightning-icon [iconName]="'utility:expand_alt'" size="xx-small" alternativeText="{{'MapComponent:button_zoom_network' | translate}}"></ui-lightning-icon></button>
        </div>
        <ui-lightning-vertical-btn-group [btnGroupOptions]="verticalBtnsOptions" (action)="mapControl($event)"></ui-lightning-vertical-btn-group>
    </div>
    <a rel="noopener" href="https://doc.galigeo.com/retail-network/" target="_blank" id="documentation-button"><span class="slds-m-right_xx-small">{{'MapComponent:help_center' | translate}}</span><ui-lightning-icon [iconName]="'utility:help_center'" size="xx-small" alternativeText="{{'MapComponent:help_center' | translate}}"></ui-lightning-icon></a>
</div>
<div class="add-poi-container" *ngIf="addPoi">
    <ui-lightning-search-adress class="add-poi-lookup" [lookupOptions]="lookupOptions" (onSelect)="addNewPoi($event)"
    (onClose)="closeInput($event)"></ui-lightning-search-adress>
    <div class="slds-region_narrow">
        <div class="slds-is-relative">
            <div class="slds-notify slds-notify_toast slds-theme_info" role="status">
                <span class="slds-assistive-text">info</span>
                <div class="slds-notify__content">
                    <h2 class="slds-text-heading_small"><span class="slds-icon_container slds-icon-utility-info slds-m-right_small slds-no-flex slds-align-top" title="Description of icon when needed">
                        <svg class="slds-icon slds-icon_small" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
                        </svg>
                    </span>
                    {{'MapComponent:click_to_add_poi' | translate:'Or click directly on the map to add a point'}}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
