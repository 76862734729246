import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewContainerRef } from '@angular/core';
import {
  AbstractLayer,
  AbstractMap,
  GeometryType,
  LayerOptions,
  LayerType,
  MapFactory,
  Style,
  StyleOptions,
  Utils
} from '@galigeo-store/map';
import { AuthguardService, GeocodeService, PoiService } from '@galigeo-store/retail-services';
import { AlertOptions, BusinessType, ConfirmOptions, DataTable, DataTableToGeoJson, Field, FieldType, MESSAGE_LEVEL, MessageOptions, POSITION, Record } from '@galigeo-store/shared-models';
import { AbstractComponent, ButtonGrpOptions, EventService, GgoEvent, LanguageService, LookupOptions, MessageService, ModalService, TableEvent } from '@galigeo-store/ui-lightning';
import { LocaleMapComponent } from '../locales/localeMapComponent';
import { ModalAddressComponent } from '../modal-address/modal-address.component';
import { LOGO } from '../models/logo';
import { Network } from '../models/network';

@Component({
  selector: 'retail-edit-map',
  templateUrl: './edit-map.component.html',
  styleUrls: ['./edit-map.component.css'],
})
export class EditMapComponent extends AbstractComponent implements AfterViewInit {
  features: [] = [];
  showControlLookup!: boolean;
  dataTableGeocode!: any;
  ngAfterViewInit(): void {
    this.initMap();
  }
  @Input() network!: Network;
  @Input() addPoi!: boolean;
  @Output() action = new EventEmitter<any>();
  @Output() closeAddPoi = new EventEmitter<any>();
  @Output() noGeomWarn = new EventEmitter<any>();
  lookupOptions: LookupOptions = {
    label: undefined,
    debounceTime: 150,
    maxLines: 5,
    icon: 'utility:search',
    showCancel: true,
  }
  controlLookupOptions: LookupOptions = {
    label: undefined,
    debounceTime: 150,
    maxLines: 5,
    icon: 'utility:search',
    showCancel: false,
    inputHeight: '30px',
  }
  
  verticalBtnsOptions: ButtonGrpOptions = {
    items: []
  };
  public map!: AbstractMap;
  public poiLayer!: AbstractLayer;
  geojson: any;
  public isSpinnerCartActive = false;
  public isAddPointMode = false;
  public logos: any [] = [];
  constructor(private renderer: Renderer2, private el: ElementRef,
    private messageService: MessageService,
    private languageService: LanguageService,
    override eventService: EventService,
    private modalService: ModalService,
    private poiService: PoiService,
    private vrc: ViewContainerRef,
    private authguardService: AuthguardService,
    private geocodeService: GeocodeService,) {
    super(eventService);
    this.languageService.addTranslation('MapComponent', new LocaleMapComponent().locale);
    this.listenEvent().subscribe((event: GgoEvent) => {
      switch (event.action) {
        case 'refresh':
          this.network = event.obj.network;
          this.refreshMap(event.obj.setExtent);
          break;

        case 'highlight': {
          console.log('highlight', event.obj);
          const geom = event.obj.data[0].values[event.obj.getFieldIndex(BusinessType.GEOMETRY)];
          if(geom) {
            this.isSpinnerCartActive = true;
            this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translate('global_spinner_message')});
            this.map.flyTo(geom.coordinates, 18);
            setTimeout(() => {
              if(this.isSpinnerCartActive) {
                this.eventService.emitEvent('global-spinner', { action: 'hide', obj: this.translate('global_spinner_message')});
              }
              this.isSpinnerCartActive = false;
            }, 5000);
          } else {
            console.error('No geometry found in the record', event.obj.data[0]);
          }
          break;
        }
        case 'activateAddPointIntheMap':
          this.isAddPointMode = true;
          break;
        case 'cancelAddPointIntheMap':
          this.isAddPointMode = false;
          break;
      }
    });
    this.verticalBtnsOptions.items = [
      { icon: 'utility:add', action: 'zoomin', title: this.translate('button_zoom_in') },
      { icon: 'utility:dash', action: 'zoomout', title: this.translate('button_zoom_out') },
      { icon: 'utility:guidance', action: 'reset_north', title: this.translate('button_reset_north') },
    ];
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.showAdressLookup(2, event);
    event.stopPropagation()  
  }

  public getDefaultName(): string {
    return "map";
  }

  public translate(key: string): string {
    return this.languageService.getTranslation('MapComponent', key);
  }

  addNewPoi(event: any) {
    this.closeAddPoi.emit(true);
    this.eventService.emitEvent('map',{ action: 'cancelAddPointIntheMap', obj: {} });
    this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translate('global_spinner_message')});
    const mapBusinessTypes: { [key: string]: BusinessType } = {
      geocoder_longitude: BusinessType.LONG,
      geocoder_latitude: BusinessType.LAT,
      geocoder_address: BusinessType.ADDRESS,
      geocoder_city: BusinessType.CITY,
      geocoder_postal_code: BusinessType.POSTAL_CODE,
    };
  
    const geometryExist = !!this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.GEOMETRY)?.name ?? '');
    const latLongExist = !!this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.LAT)?.name ?? '') && !!this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.LONG)?.name ?? '');
    const longLatIndex = [
      event.value.getFieldIndex(event.value.fields.find((f: Field) => f.businessType === BusinessType.GEOCODER_LONGITUDE)?.name ?? ''),
      event.value.getFieldIndex(event.value.fields.find((f: Field) => f.businessType === BusinessType.GEOCODER_LATITUDE)?.name ?? ''),
    ];
  
    this.updateFields(event.value, BusinessType.GEOMETRY, latLongExist, longLatIndex);
  
    for (const fieldType in mapBusinessTypes) {
      const targetBusinessType = mapBusinessTypes[fieldType];
      const field = event.value.fields.find((f: Field) => f.businessType === fieldType);
  
      if (geometryExist && !latLongExist && (targetBusinessType === BusinessType.LONG || targetBusinessType === BusinessType.LAT)) {
        event.value.fields = event.value.fields.filter((f: Field) => f.businessType !== fieldType);
      }
  
      if (field) {
        this.updateFieldProperties(field, targetBusinessType, latLongExist);
      }
    }
  
    const geoAccuracyIndex = event.value.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.GEOCODER_ACCURACY)?.name ?? '');
    event.value.data[0].values[geoAccuracyIndex] = 1;
    event.value.fields[geoAccuracyIndex].visible = false;
    event.value.fields[event.value.getFieldIndex(BusinessType.GEOCODER_CITY_CODE)].visible = false;

    if (this.network.id) {
      this.poiService.addPOI({ networkId: this.network.id, poi: event.value }).subscribe((result: DataTable) => {
        this.handleAddPoiSuccess(result);
      });
    }
  }
  
  updateFields(value: any, geometryType: string, latLongExist: boolean, longLatIndex: number[]) {
    if (geometryType && !latLongExist) {
      value.fields.push(this.network.pois?.fields[this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === geometryType)?.name ?? '')]);
      value.data[0].values.push({
        type: "Point",
        coordinates: [
          value.data[0].values[longLatIndex[0]],
          value.data[0].values[longLatIndex[1]],
        ],
      });
  
      value.data[0].values = value.data[0].values.filter((v: any, i: number) => !longLatIndex.includes(i))
    }
  }
  
  updateFieldProperties(field: Field, targetBusinessType: BusinessType, latLongExist: boolean) {
    const targetFieldIndex = this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === targetBusinessType)?.name ?? '');
  
    field.businessType = targetBusinessType;
  
    if (targetFieldIndex !== undefined) {
      field.name = this.network.pois?.fields[targetFieldIndex].name ? this.network.pois?.fields[targetFieldIndex].name : targetBusinessType;
      field.alias = this.network.pois?.fields[targetFieldIndex].alias ? this.network.pois?.fields[targetFieldIndex].alias : targetBusinessType;
    }

    if (latLongExist) {
      field.name = field.name.replace('geocoder_', '');
      if (field.businessType === BusinessType.LAT) {
        field.name = this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.LAT)?.name ?? "latitude" 
        field.alias = this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.LAT)?.alias ?? "latitude"
      }
      if (field.businessType === BusinessType.LONG) {
        field.name = this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.LONG)?.name ?? "longitude"
        field.alias = this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.LONG)?.alias ?? "longitude"
      }
    }
  }
  
  handleAddPoiSuccess(result: DataTable) {
    this.messageService.toast(new AlertOptions({ message: this.translate('poi_added'), level: MESSAGE_LEVEL.INFO, timeout: 3000, position: POSITION.TOP_CENTER }));
    this.network.pois = result;
    this.eventService.emitEvent('table-network', { action: TableEvent.REFRESH, obj: null });
    this.refreshMap(false);
    this.eventService.emitEvent('global-spinner', { action: 'hide', obj: this.translate('global_spinner_message_delete')});
    const geometryFieldIndex = this.network.pois?.getFieldIndex(BusinessType.GEOMETRY);
    this.map.flyTo(this.network.pois?.data[0].values[geometryFieldIndex]?.coordinates, 18);
    this.action.emit({ action: 'highlight', obj: this.network.pois?.data[0] });
  }
  ngOnDestroy() {
    // Supprimez l'écouteur d'événements pour éviter les fuites de mémoire
    this.renderer.destroy();
  }
  ngOnInit(): void {
    this.lookupOptions.placeholder = this.languageService.getTranslation('MapComponent', 'search_placeholder');
  }

  /**
   * Refresh the map by reparsing the input datatable
   */
  async refreshMap(setExtent: boolean) {
    if (this.poiLayer) {
      await this.loadAllLogos();
      this.poiLayer.closePopup();
      this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translate('global_spinner_message')});
      this.geojson = this.dataTableToGeoJson(this.network.pois);
      this.poiLayer.setData(this.geojson);
      if (setExtent) {
        this.map.setExtent(this.poiLayer.getExtent());
      }
    }
  }
  async loadAllLogos(): Promise<boolean>{
    return new Promise((resolve) => {
    this.logos = [];
    const logoIndex = this.network.pois?.getFieldIndex('logo_id');
    if (!this.network.logo) this.network.logo = LOGO.DEFAULT_LOGO;
    if (logoIndex !== undefined) {
      let resIndexSent = 0;
      let resIndexRecived = 0;
      this.network.pois?.data.forEach(async(record: Record) => {
        if(record.values[logoIndex] !== null && !this.logos.find((l: any) => l.name === "logoUrl_" + record.values[logoIndex])) {
          resIndexSent++;
          this.logos.push({ name: "logoUrl_" + record.values[logoIndex], url: '' });
          this.poiService.getLogoById(record.values[logoIndex]).subscribe(async (result: DataTable) => {
            
            if(this.logos.find((l) => l.name === "logoUrl_" + record.values[logoIndex])) {
              const newLogo: string = await this.downscaleImage(result.data[0].values[2], 50);
              this.logos.find(l => l.name === "logoUrl_" + record.values[logoIndex]).url =  await Utils.mergeLogoWithPin(newLogo);
              this.logos.find(l => l.name === "logoUrl_" + record.values[logoIndex]).base64 = newLogo;
            }
            resIndexRecived++;
          });
         
        }
        
      });
      let interval = setInterval(async () => {
        if(resIndexSent === resIndexRecived) {
          clearInterval(interval);
          await this.map.loadImages(this.logos);
          resolve( true);
        }
      }, 200);
    } else {
      (async () => {
        if (this.network.logo) {
          this.logos.push({ name: "logoUrl", url: await Utils.mergeLogoWithPin(this.network.logo), base64:  this.network.logo});
        }
        await this.map.loadImages(this.logos);
      })();
    }
  });
    
  }
// Take an image URL, downscale it to the given width, and return a new image URL.
  async downscaleImage(imageUrl: string, newWidth: number): Promise<string> {
    // Create a temporary image so that we can compute the height of the downscaled image.
    const image = new Image();
    image.src = imageUrl;
    return new Promise((resolve, reject) => {
      image.onload = () => {
        const newHeight = Math.floor(image.height / image.width * newWidth);
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const context = canvas.getContext('2d');
        context?.drawImage(image, 0, 0, newWidth, newHeight);
        resolve(canvas.toDataURL());
      };
      image.onerror = reject;
    });
  }
  async initMap() {
    try {
        console.log("dataTable", this.network.pois);
        await this.setupMap();
        await this.loadAllLogos();
        this.setupGeojson();
         
        this.setupSpinner();
        await this.setupPoiLayer();
    } catch (e) {
        console.error(e);
        this.handleInitMapError(e);
    }
}

  private setupGeojson() {
      this.geojson = this.dataTableToGeoJson(this.network.pois);
      console.log("geojson", this.geojson);
  }

  private async setupMap() {
      this.map = await MapFactory.getMap({
          elementId: 'map-wizard'
      });
      this.setupMapListeners();
  }

  private setupSpinner() {
      this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translate('global_spinner_message') });
  }

  private async setupPoiLayer() {
      this.poiLayer = await this.map.addLayer(
          new LayerOptions({
              id: 'poi-layer',
              type: LayerType.Simple,
              popup: true,
              popupContent: this.getPopupTemplate(),
              dragable: this.authguardService.hasCapability('editNetwork'), // by default the points can be dragged
              style: new StyleOptions({
                  imgIdField: '_logo',
                  cluster: {clusterMaxZoom: 7},
                  allowOverlap: true,
                  type: Style.Symbol,
                  geometryType: GeometryType.Point,
                  offset: [0, -23],
                  popupOffset: [0, -23]
              }),
          }),
          this.geojson
      );
      this.map.setExtent(this.poiLayer.getExtent());
      this.setupPoiLayerListeners();
  }

  private setupMapListeners() {
      this.map.addEventListener('drawend', (e: any) => {
          console.log('drawend', e);
          this.hideSpinner();
      });
      this.map.addEventListener('click', async (e: any) => {
          console.log('click', e);
          if(this.isAddPointMode) {
              this.dataTableGeocode = await this.geocodeService.reverseGeocode(e.lngLat.lat, e.lngLat.lng);
              if (this.dataTableGeocode.id === 'reverse-not-found') {
                  setTimeout(() => {
                      this.messageService.alert(new MessageOptions({ message: this.languageService.getTranslation('MapComponent', 'geocoder_not_found') })); 
                  }, 200);
              } else {
                this.dataTableGeocode.fields[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_LONGITUDE)].type = FieldType.DOUBLE;
                this.dataTableGeocode.fields[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_LATITUDE)].type = FieldType.DOUBLE;
                this.dataTableGeocode.data[0].values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_LONGITUDE)] = e.lngLat.lng;
                this.dataTableGeocode.data[0].values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_LATITUDE)] = e.lngLat.lat;
                this.dataTableGeocode.data.splice(1);
                this.addNewPoi({ value: this.dataTableGeocode });
              }
            }
      });
      
  }

  private hideSpinner() {
      this.eventService.emitEvent('global-spinner', { action: 'hide', obj: this.translate('global_spinner_message') });
      this.isSpinnerCartActive = false;
  }
      
      
  private setupPoiLayerListeners() {
      this.poiLayer.addEventListener('click', (e: any) => {
        if(!this.isAddPointMode) {
          this.handlePoiLayerClick(e);
        }
      });
      this.poiLayer.addEventListener('dragend', (e: any) => {
          this.handlePoiLayerDragend(e);
      });
  }

  private handlePoiLayerClick(e: any) {
      const record = this.network.pois?.data.find((r: Record) => r.recordId === e[0].properties.recordId);
      this.action.emit({ action: 'highlight', obj: record });
      this.setupDeleteButtonListener();
  }

  private setupDeleteButtonListener() {
      // Listen the delete button on the infowindow
      console.log('this.el.nativeElement', this.el.nativeElement);
      const nonAngularElement = this.el.nativeElement.querySelector('.map_delete_btn');
      if (nonAngularElement) {
          this.renderer.listen(nonAngularElement, 'click', (e) => {
              // Votre code de gestion de l'événement click ici
              this.handleDeleteButtonClick(e);
          });
      }
  }

  private handleDeleteButtonClick(e: any) {
      const ggoId = e.target.attributes['data-id'].value;
      console.log('Delete POI ' + ggoId, e);
      if (!this.network.pois) return;
      for (let record of this.network.pois.data) {
          if (record.values[0] + '' === ggoId) {
              this.confirmDeletePoi(record, ggoId);
          }
      }
  }

  private confirmDeletePoi(record: Record, ggoId: any) {
      this.messageService.confirm(new ConfirmOptions({
          message: this.translate('confirm_delete_poi'),
          callback: (confirmDelete: boolean) => {
              if (confirmDelete) {
                  this.deletePoi(record, ggoId);
              }
          }
      }));
  }

    private deletePoi(record: Record, ggoId: any) {
      console.log('Emit delete', record.values[0]);

      if (this.network.pois) {
        this.action.emit({ action: 'delete', networkId: this.network.id, ggoId: record.values[0], data: new DataTable({ fields: this.network.pois.fields, data: [record] }) });
        this.geojson.features = this.geojson.features.filter((f: any) => f.properties.ggo_id !== ggoId);
        this.poiLayer.setData(this.geojson);
      }
    }

  private handlePoiLayerDragend(e: any) {
      console.log('dragend', e.feature.properties.poi_id, e);
      const modalAdressInstance = this.modalService.inject(ModalAddressComponent);
      modalAdressInstance.feature = e.feature;
      modalAdressInstance.network = this.network;
      modalAdressInstance.callback = (editData: DataTable) => {
          if (editData) {
              this.action.emit({ action: 'edit', data: editData });
          } else {
              // move the point back to its original position
              this.poiLayer.moveFeature("recordId", e.feature.properties.recordId, e.startPosition);
          }
      };
      modalAdressInstance.open();
  }

  private handleInitMapError(e: any) {
      this.messageService.alert(new AlertOptions({ message: 'Failed to init map', detailedMessage: e }));
  }

  closeInput(e: any) {
    this.closeAddPoi.emit(e);
    this.eventService.emitEvent('map',{ action: 'cancelAddPointIntheMap', obj: {} });
  }

  dataTableToGeoJson(dataTable: DataTable | undefined): any {
    console.log("logos", this.logos);
    if (dataTable?.data.length === 0 || dataTable === undefined) return { features: []};
    const geojson = new DataTableToGeoJson().convert(dataTable);
    geojson.features.forEach(async (f: any) => {
      if(f.geometry === null) return;
      const properties: any = {};
      properties._x_long = new Intl.NumberFormat(undefined, { maximumSignificantDigits: 5 }).format(f.geometry.coordinates[0]);
      properties._y_lat = new Intl.NumberFormat(undefined, { maximumSignificantDigits: 5 }).format(f.geometry.coordinates[1]);
      properties._logo = f.properties['logo_id'] ? "logoUrl_" + f.properties['logo_id'] : 'logoUrl';
      properties._mapAddress = this.objectToAddress(f.properties);
      properties._mapName = this.objectToName(f.properties);
      properties._updateInfo = this.objectToUpdateInfo(f.properties);
      properties.recordId = f.properties.recordId;
      properties._base64 = this.logos.find( l => l.name === properties._logo) ? this.logos.find( l => l.name === properties._logo).base64 : '';
      
      
      // add the address info
      properties[BusinessType.ADDRESS] = f.properties[BusinessType.ADDRESS];
      properties[BusinessType.CITY] = f.properties[BusinessType.CITY];
      properties[BusinessType.POSTAL_CODE] = f.properties[BusinessType.POSTAL_CODE];
      
      f.properties = properties;
    });
    return geojson;
  }

  private objectToAddress(json: any): string {
    const addressParts: string[] = [];
    if (json[BusinessType.ADDRESS]) addressParts.push(json[BusinessType.ADDRESS]);
    if (json[BusinessType.POSTAL_CODE]) addressParts.push(json[BusinessType.POSTAL_CODE]);
    if (json[BusinessType.CITY]) addressParts.push(json[BusinessType.CITY]);
    return addressParts.join(', ');
  }
  /**
   * Extract the name of the POI from the json
   * @param json 
   * @returns 
   */
  private objectToName(json: any): string {
    if (json[BusinessType.NAME]) return json[BusinessType.NAME];
    if (json[BusinessType.ID]) return json[BusinessType.ID];
    let nameParts: string[] = [];
    for (let i of Object.keys(json)) {
      if(i !== '' && i !== 'logoImage' && json[i] !== '' && json[i] !== null && json[i] !== undefined )
      nameParts.push(json[i]);
    }
    let name = nameParts.join(', ');
    if (name.length > 150) {
      name = name.substring(0, 150) + '...';
    }
    return name;
  }

  private objectToUpdateInfo(json: any): string {
    let info = '';
    if(json[BusinessType.LAST_UPDATED_AT]) {
      info += this.translate('last_update') + ' ' + new Date(json[BusinessType.LAST_UPDATED_AT]).toLocaleDateString(undefined, {});
    }
    if(json[BusinessType.LAST_UPDATED_BY]) {
      info += ' ' + this.translate('last_update_by') + ' ' + json[BusinessType.LAST_UPDATED_BY];
    }
    return info;
  }

  /**
   * 
   * @returns The HTML for the infowindow
   */
  getPopupTemplate() {
    let template = `
    
    <div class="slds-grid">
      <header class="slds-media slds-media_center slds-has-flexi-truncate">
        <div class="slds-media__figure">
          <span class="slds-icon_container" title="account">
            <img src="{{_base64}}" style="width: 1.5rem"/>
            <span class="slds-assistive-text">${this.network.name}</span>
          </span>
        </div>
        <div class="slds-media__body">
          <h2 class="slds-card__header-title" title="{{_mapName}}" style="max-width:150px">
            <span class="slds-truncate">{{_mapName}}</span>
          </h2>
        </div>
      </header>
    </div>
    <div class="slds-card__body slds-card__body_inner">
      <div class="slds-grid slds-gutters">
        <div class="slds-col">
          <span class="slds-text-body_small">{{_mapAddress}}</span>
        </div>
      </div>
      <div class="slds-grid slds-gutters">
        <div class="slds-col">
          <span class="slds-text-body_small" style="color:grey">{{_x_long}}, {{_y_lat}}</span>
        </div>
      </div>
      <div class="slds-grid slds-gutters">
        <div class="slds-col">
          <span class="slds-text-body_small" style="color: #8080808c;">{{_updateInfo}}</span>
        </div>
      </div>
    </div>
  `;
    return template;
  }

  mapControl(e: any) {
    switch (e.action) {
      case 'zoomin':
        this.map.flyTo(this.map.getCenter(), this.map.getZoom() + 1);
        break;
      case 'zoomout':
        this.map.flyTo(this.map.getCenter(), this.map.getZoom() - 1);
        break;
      case 'reset_north':
        this.map.resetNorth();
        break;
    }
  }

  setExtent() {
    this.map.setExtent(this.poiLayer.getExtent());
  }

  showAdressLookup(id: any, event: any) {
    if(id == 1) {
      this.showControlLookup = true;
      event.stopPropagation()
    } else {
      this.showControlLookup = false;
    }
  }

  flyToAdress(e: any) {
    this.showControlLookup = false;
    const coordinates: [number, number] = [e.value.data[0].values[e.value.getFieldIndex(BusinessType.GEOCODER_LONGITUDE)], e.value.data[0].values[e.value.getFieldIndex(BusinessType.GEOCODER_LATITUDE)]];
    this.map.flyTo(coordinates, 18);
  }
}